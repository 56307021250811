import * as React from 'react';

import { dateTimeFormat, PanelData, dateMath, DataSourceApi } from '@grafana/data';
import { getDataSourceSrv } from '@grafana/runtime';
import config from 'app/core/config';
import { getDashboardSrv } from 'app/features/dashboard/services/DashboardSrv';

import { SpectrogramSettings } from './types';
import { getDico } from './useTranslation';
import {
  getUserSettingsQuery,
  influxRequest,
  getFirstSettingsReceivedQuery,
  getNoOtherSettingsQuery,
  getTemplates,
  getSpectrumUnit,
  getDecimals,
  getNewTargets,
  convertSettingsValue,
} from './utils';
import { AnnotProps, getAnnot } from './utils_annotations';

const DATASOURCE_NAME = 'asystom_db';

export const useFetchSpectrumSettings = (panelData: PanelData, width: number, timezone: string) => {
  const dico = React.useMemo(() => getDico(), []);
  const templates = React.useMemo(() => getTemplates(), []);

  const dashboard = React.useMemo(() => getDashboardSrv().getCurrent(), []);
  const timePanel = React.useMemo(
    () => ({ from: panelData.timeRange.from.valueOf(), to: panelData.timeRange.to.valueOf() }),
    [panelData.timeRange]
  );
  const panel = React.useMemo(
    () => dashboard?.getPanelById(panelData.request?.panelId!),
    [dashboard, panelData.request?.panelId]
  );
  const queryRunner = panel?.getQueryRunner();
  const user = config.bootData.user;

  const DEFAULT_SETTINGS = {
    spectrumType: '--',
    sensorType: `${dico.sensorType.microphone}`,
    minFreq: 0,
    maxFreq: 80000,
    orientation: '--',
    cutoffValue: '0',
    dateCreation: '--',
    dateReception: '--',
    isDefault: true,
    beaconVersion: '0.0',
  };

  const DEFAULT_GRAPH_CONFIG = {
    title: `${dico.ultrasound.toUpperCase()} - ${dico.spectrogram.toUpperCase()}`,
    decimals: 1,
    yaxesFormat: 'dB',
  };

  const [spectrumSettings, setSpectrumSettings] = React.useState({} as SpectrogramSettings);
  const [panelError, setPanelError] = React.useState('');
  const [settingAnnotation, setSettingAnnotation] = React.useState<AnnotProps>();

  const loadDefaults = (datasource: DataSourceApi) => {
    const targets = getNewTargets(
      DEFAULT_SETTINGS,
      templates!.macAddress,
      '$timeFilter',
      dico.spectrumType,
      dico.sensorType,
      templates!.lengthUnit
    );

    setSpectrumSettings(DEFAULT_SETTINGS);

    if (panel) {
      panel?.setProperty('title', DEFAULT_GRAPH_CONFIG.title);

      const newConfig = { ...panel?.fieldConfig };
      newConfig!.defaults!.decimals = 1;
      newConfig!.defaults!.unit = 'dB';
      panel?.setProperty('fieldConfig', newConfig);
      // panel?.updateQueries({ queries: targets, dataSource: { uid: datasource.uid, type: 'influxdb' } });
      queryRunner?.run({
        queries: targets,
        datasource: datasource,
        timezone: dashboard?.timezone,
        timeRange: {
          from: dateMath.parse(panelData.timeRange.from)!,
          to: dateMath.parse(panelData.timeRange.to)!,
          raw: panelData.timeRange,
        },
        maxDataPoints: width,
        minInterval: null,
      });
      panel?.render();
    }
  };

  /** Get custom spectrogram settings */
  const getSpectrumSettings = React.useCallback(async () => {
    setPanelError('');

    // Retrieve last settings sent by user (where version ='')
    // before the end time of panel window (panel range to)

    const datasourceInstance = await getDataSourceSrv()
      .get(DATASOURCE_NAME)
      .catch(() => console.error(`Error getting datasource ${DATASOURCE_NAME}!`));

    const queryUserSettings = getUserSettingsQuery(templates!.macAddress, timePanel.to);
    const settingsValues = await influxRequest(datasourceInstance!, queryUserSettings).catch((err) =>
      console.error('error getting beacon settings', err)
    );

    if (!settingsValues?.length) {
      loadDefaults(datasourceInstance!);
      return;
    }

    const userSettingsTimestamp = settingsValues[0].values[0][0];
    const userSettings = settingsValues[0].values[0][2];

    //  Get first settings received equal to settings sent by user
    const queryFirstSettingsReceived = getFirstSettingsReceivedQuery(
      templates!.macAddress,
      userSettings?.toLowerCase(),
      userSettingsTimestamp
    );

    const dataFirstReceived = await influxRequest(datasourceInstance!, queryFirstSettingsReceived).catch((err) => {
      console.error('error getting beacon settings', err);
      return;
    });

    // If no result, warning message: wait for settings to be received or settings received != settings sent by user
    if (!dataFirstReceived?.length) {
      setPanelError(`${dico.noBeaconSettingsReceived} ${dateTimeFormat(userSettingsTimestamp)}`);
      loadDefaults(datasourceInstance!);
      return;
    }

    let beaconVersion = dataFirstReceived[0]?.values[0][1];

    if (!beaconVersion) {
      loadDefaults(datasourceInstance!);
      return;
    }

    let currentBeaconVersion = 0.0;
    currentBeaconVersion = +beaconVersion.replace(/[^\d.]/g, '');
    beaconVersion = currentBeaconVersion.toString(10);

    let settingsReceivedDate = 0;

    // Custom spectrogram is not applicable
    if (isNaN(currentBeaconVersion) || currentBeaconVersion < 4.45) {
      loadDefaults(datasourceInstance!);
      return;
    } else {
      let settings = { ...DEFAULT_SETTINGS };
      settings.beaconVersion = beaconVersion;
      settingsReceivedDate = dataFirstReceived[0].values[0][0];
      settings.dateReception = dateTimeFormat(settingsReceivedDate);

      // Check that beacon didn't send any other settings in the current time range (used for special cases)
      const queryCheckNoOtherSettings = getNoOtherSettingsQuery(
        templates!.macAddress,
        userSettings?.toLowerCase(),
        timePanel,
        settingsReceivedDate
      );

      const dataCheckNoOtherSettings = await influxRequest(datasourceInstance!, queryCheckNoOtherSettings).catch(
        (err) => {
          console.error('error getting beacon settings', err);
        }
      );

      // If no settings different from current settings are found, continue processing settings value
      if (!dataCheckNoOtherSettings?.length) {
        if (panel) {
          settings = convertSettingsValue(dataFirstReceived[0].values[0][2], settings, dico);
          settings.dateCreation = dateTimeFormat(userSettingsTimestamp);
          settings.isDefault = false;
          setSpectrumSettings(settings);
          // this.updateLegend();

          const targets = getNewTargets(
            settings,
            templates!.macAddress,
            `time >= ${settingsReceivedDate}ms AND $timeFilter`,
            dico.spectrumType,
            dico.sensorType,
            templates!.lengthUnit
          );

          const graphUnit = getSpectrumUnit(settings, dico, templates!.lengthUnit);
          const decimals = getDecimals(graphUnit);

          const newTitle = `${settings.sensorType.toUpperCase()} - ${dico.spectrogram.toUpperCase()}`;
          panel?.setProperty('title', newTitle);

          const newConfig = { ...panel?.fieldConfig };
          newConfig!.defaults!.decimals = decimals;
          newConfig!.defaults!.unit = graphUnit;
          panel?.setProperty('fieldConfig', newConfig);

          // panel?.updateQueries({ queries: targets, dataSource: { uid: datasourceInstance!.uid, type: 'influxdb' } });

          queryRunner?.run({
            queries: targets,
            datasource: datasourceInstance!,
            timezone: timezone,
            timeRange: {
              from: dateMath.parse(panelData.timeRange.from)!,
              to: dateMath.parse(panelData.timeRange.to)!,
              raw: panelData.timeRange,
            },
            maxDataPoints: width,
            minInterval: null,
          });

          const settingAnnot = getAnnot(
            settingsReceivedDate,
            settings,
            dashboard?.id,
            dashboard!.uid,
            panel.id,
            user
          );
          setSettingAnnotation(settingAnnot);
          panel?.render();
          return;
        }
      } else {
        setPanelError('Warning: in this time range, some beacon settings are different from settings sent by user.');
        panel?.setProperty('title', `${dico.spectrogram.toUpperCase()}`);
        panel?.render();
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelData.timeRange, width]);

  React.useEffect(() => {
    if (panel) {
      getSpectrumSettings();
    }
  }, [getSpectrumSettings, panel]);

  return [spectrumSettings, panelError, settingAnnotation] as const;
};
