import React, { useMemo, useState } from 'react';

import { PanelProps, DashboardCursorSync } from '@grafana/data';
import { PanelDataErrorView } from '@grafana/runtime';
import { TooltipDisplayMode } from '@grafana/schema';
import { KeyboardPlugin, TooltipPlugin, TooltipPlugin2, usePanelContext, ZoomPlugin } from '@grafana/ui';
import { TimeRange2, TooltipHoverMode } from '@grafana/ui/src/components/uPlot/plugins/TooltipPlugin2';
import { TimeSeries } from 'app/core/components/TimeSeries/TimeSeries';
import { config } from 'app/core/config';

import { SpectrumSettingsLegend } from './SpectrumSettingsLegend';
import { TimeSeriesTooltip } from './TimeSeriesTooltip';
import { Options } from './panelcfg.gen';
import { AnnotationEditorPlugin } from './plugins/AnnotationEditorPlugin';
import { AnnotationsPlugin } from './plugins/AnnotationsPlugin';
import { AnnotationsPlugin2 } from './plugins/AnnotationsPlugin2';
import { ContextMenuPlugin } from './plugins/ContextMenuPlugin';
import { ExemplarsPlugin, getVisibleLabels } from './plugins/ExemplarsPlugin';
import { OutsideRangePlugin } from './plugins/OutsideRangePlugin';
import { ThresholdControlsPlugin } from './plugins/ThresholdControlsPlugin';
import { useFetchSpectrumSettings } from './useFetchSpectrumSettings';
import { getTimezones, isTooltipScrollable, prepareGraphableFields, regenerateLinksSupplier, BEACON_VERSION_FOR_CUSTOM_SPECTROGRAM } from './utils';
import { addAnnotation, getNewAnnotation } from './utils_annotations';

interface TimeSeriesPanelProps extends PanelProps<Options> {}

export const TimeSeriesPanel = ({
  data,
  timeRange,
  timeZone,
  width,
  height,
  options,
  fieldConfig,
  onChangeTimeRange,
  replaceVariables,
  id,
}: TimeSeriesPanelProps) => {
  const { sync, canAddAnnotations, onThresholdsChange, canEditThresholds, showThresholds, dataLinkPostProcessor } =
    usePanelContext();


  const frames = useMemo(() => prepareGraphableFields(data.series, config.theme2, timeRange), [data.series, timeRange]);
  const timezones = useMemo(() => getTimezones(options.timezone, timeZone), [options.timezone, timeZone]);
  const [spectrumSettings, panelError, settingAnnotation] = useFetchSpectrumSettings(data, width, timeZone);

  // Add settings reception date as an annotation
  const extendedAnnotations = useMemo(() => {
    if (
      settingAnnotation &&
      settingAnnotation?.time > timeRange.from.valueOf() &&
      settingAnnotation?.time < timeRange.to.valueOf()
    ) {
      if (data?.annotations?.length) {
        let annotations = addAnnotation(data.annotations, settingAnnotation);
        annotations[0].length += 1;
        return annotations;
      }
      const { time, text, user, panelId, dashboardId, dashboardUID } = settingAnnotation;
      const singleAnnot = getNewAnnotation(time, text, user, panelId, dashboardId, dashboardUID);
      return singleAnnot;
    }
    return data.annotations;
  }, [data.annotations, settingAnnotation, timeRange]);

  const enableAnnotationCreation = Boolean(canAddAnnotations && canAddAnnotations());
  const showNewVizTooltips =
    config.featureToggles.newVizTooltips && (sync == null || sync() !== DashboardCursorSync.Tooltip);
  // temp range set for adding new annotation set by TooltipPlugin2, consumed by AnnotationPlugin2
  const [newAnnotationRange, setNewAnnotationRange] = useState<TimeRange2 | null>(null);

  if (!frames || panelError) {
    return (
      <PanelDataErrorView
        panelId={id}
        message={panelError}
        fieldConfig={fieldConfig}
        data={data}
        needsTimeField={true}
        needsNumberField={true}
      />
    );
  }

  // which annotation are we editing?
  // are we adding a new annotation? is annotating?
  // console.log(data.annotations);

  // annotations plugin includes the editor and the renderer
  // its annotation state is managed here for now
  // tooltipplugin2 receives render with annotate range, callback should setstate here that gets passed to annotationsplugin as newAnnotaton or editAnnotation

  return (
    <>
      <TimeSeries
        frames={frames}
        structureRev={data.structureRev}
        timeRange={timeRange}
        timeZone={timezones}
        width={width}
        height={spectrumSettings?.beaconVersion > '4.44' && !spectrumSettings.isDefault ? height - 55 : height}
        legend={options.legend}
        options={options}
      >
        {(uplotConfig, alignedDataFrame) => {
          if (alignedDataFrame.fields.some((f) => Boolean(f.config.links?.length))) {
            alignedDataFrame = regenerateLinksSupplier(
              alignedDataFrame,
              frames,
              replaceVariables,
              timeZone,
              dataLinkPostProcessor
            );
          }

          return (
            <>
              {!showNewVizTooltips && <KeyboardPlugin config={uplotConfig} />}
              {options.tooltip.mode === TooltipDisplayMode.None || (
                <>
                  {showNewVizTooltips ? (
                    <TooltipPlugin2
                      config={uplotConfig}
                      hoverMode={
                        options.tooltip.mode === TooltipDisplayMode.Single ? TooltipHoverMode.xOne : TooltipHoverMode.xAll
                      }
                      queryZoom={onChangeTimeRange}
                      clientZoom={true}
                      render={(u, dataIdxs, seriesIdx, isPinned = false, dismiss, timeRange2, viaSync) => {
                        if (viaSync) {
                          return null;
                        }

                        if (enableAnnotationCreation && timeRange2 != null) {
                          setNewAnnotationRange(timeRange2);
                          dismiss();
                          return;
                        }

                        const annotate = () => {
                          let xVal = u.posToVal(u.cursor.left!, 'x');

                          setNewAnnotationRange({ from: xVal, to: xVal });
                          dismiss();
                        };

                        return (
                          // not sure it header time here works for annotations, since it's taken from nearest datapoint index
                          <TimeSeriesTooltip
                            frames={frames}
                            seriesFrame={alignedDataFrame}
                            dataIdxs={dataIdxs}
                            seriesIdx={seriesIdx}
                            mode={options.tooltip.mode}
                            sortOrder={options.tooltip.sort}
                            isPinned={isPinned}
                            annotate={enableAnnotationCreation ? annotate : undefined}
                            scrollable={isTooltipScrollable(options.tooltip)}
                          />
                        );
                      }}
                      maxWidth={options.tooltip.maxWidth}
                      maxHeight={options.tooltip.maxHeight}
                    />
                  ) : (
                    <>
                      <ZoomPlugin config={uplotConfig} onZoom={onChangeTimeRange} withZoomY={true} />
                      <TooltipPlugin
                        frames={frames}
                        data={alignedDataFrame}
                        config={uplotConfig}
                        mode={options.tooltip.mode}
                        sortOrder={options.tooltip.sort}
                        sync={sync}
                        timeZone={timeZone}
                      />
                    </>
                  )}
                </>
              )}
              {/* Renders annotation markers*/}
              {showNewVizTooltips ? (
                <AnnotationsPlugin2
                  annotations={extendedAnnotations ?? []}
                  config={uplotConfig}
                  timeZone={timeZone}
                  newRange={newAnnotationRange}
                  setNewRange={setNewAnnotationRange}
                />
              ) : (
                extendedAnnotations && (
                  <AnnotationsPlugin annotations={extendedAnnotations} config={uplotConfig} timeZone={timeZone} />
                )
              )}

              {/*Enables annotations creation*/}
              {!showNewVizTooltips ? (
                enableAnnotationCreation ? (
                  <AnnotationEditorPlugin data={alignedDataFrame} timeZone={timeZone} config={uplotConfig}>
                    {({ startAnnotating }) => {
                      return (
                        <ContextMenuPlugin
                          data={alignedDataFrame}
                          config={uplotConfig}
                          timeZone={timeZone}
                          replaceVariables={replaceVariables}
                          defaultItems={[
                            {
                              items: [
                                {
                                  label: 'Add annotation',
                                  ariaLabel: 'Add annotation',
                                  icon: 'comment-alt',
                                  onClick: (e, p) => {
                                    if (!p) {
                                      return;
                                    }
                                    startAnnotating({ coords: p.coords });
                                  },
                                },
                              ],
                            },
                          ]}
                        />
                      );
                    }}
                  </AnnotationEditorPlugin>
                ) : (
                  <ContextMenuPlugin
                    data={alignedDataFrame}
                    frames={frames}
                    config={uplotConfig}
                    timeZone={timeZone}
                    replaceVariables={replaceVariables}
                    defaultItems={[]}
                  />
                )
              ) : undefined}
              {extendedAnnotations && (
                <ExemplarsPlugin
                  visibleSeries={getVisibleLabels(uplotConfig, frames)}
                  config={uplotConfig}
                  exemplars={extendedAnnotations}
                  timeZone={timeZone}
                />
              )}

              {((canEditThresholds && onThresholdsChange) || showThresholds) && (
                <ThresholdControlsPlugin
                  config={uplotConfig}
                  fieldConfig={fieldConfig}
                  onThresholdsChange={canEditThresholds ? onThresholdsChange : undefined}
                />
              )}

              <OutsideRangePlugin config={uplotConfig} onChangeTimeRange={onChangeTimeRange} />
            </>
          );
        }}
      </TimeSeries>
      {/* Custom spectrogram legend */}
      {spectrumSettings?.beaconVersion >= BEACON_VERSION_FOR_CUSTOM_SPECTROGRAM.toString() &&
        !spectrumSettings.isDefault && <SpectrumSettingsLegend spectrumSettings={spectrumSettings} />}
    </>
  );
};
