import {
  DataFrame,
  DataSourceApi,
  Field,
  FieldType,
  getDisplayProcessor,
  getLinksSupplier,
  GrafanaTheme2,
  DataLinkPostProcessor,
  InterpolateFunction,
  isBooleanUnit,
  SortedVector,
  TimeRange,
} from '@grafana/data';
import { convertFieldType } from '@grafana/data/src/transformations/transformers/convertFieldType';
import { applyNullInsertThreshold } from '@grafana/data/src/transformations/transformers/nulls/nullInsertThreshold';
import { nullToValue } from '@grafana/data/src/transformations/transformers/nulls/nullToValue';
import { getBackendSrv, getTemplateSrv } from '@grafana/runtime';
import { GraphFieldConfig, LineInterpolation, TooltipDisplayMode, VizTooltipOptions } from '@grafana/schema';
import { buildScaleKey } from '@grafana/ui/src/components/uPlot/internal';

import { SerieSpectrumProps, SpectrogramSettings } from './types';
import { DicoProps } from './useTranslation';

type ScaleKey = string;

// this will re-enumerate all enum fields on the same scale to create one ordinal progression
// e.g. ['a','b'][0,1,0] + ['c','d'][1,0,1] -> ['a','b'][0,1,0] + ['c','d'][3,2,3]
function reEnumFields(frames: DataFrame[]): DataFrame[] {
  let allTextsByKey: Map<ScaleKey, string[]> = new Map();

  let frames2: DataFrame[] = frames.map((frame) => {
    return {
      ...frame,
      fields: frame.fields.map((field) => {
        if (field.type === FieldType.enum) {
          let scaleKey = buildScaleKey(field.config, field.type);
          let allTexts = allTextsByKey.get(scaleKey);

          if (!allTexts) {
            allTexts = [];
            allTextsByKey.set(scaleKey, allTexts);
          }

          let idxs: number[] = field.values.toArray().slice();
          let txts = field.config.type!.enum!.text!;

          // by-reference incrementing
          if (allTexts.length > 0) {
            for (let i = 0; i < idxs.length; i++) {
              idxs[i] += allTexts.length;
            }
          }

          allTexts.push(...txts);

          // shared among all enum fields on same scale
          field.config.type!.enum!.text! = allTexts;

          return {
            ...field,
            values: idxs,
          };

          // TODO: update displayProcessor?
        }

        return field;
      }),
    };
  });

  return frames2;
}

/**
 * Returns null if there are no graphable fields
 */
export function prepareGraphableFields(
  series: DataFrame[],
  theme: GrafanaTheme2,
  timeRange?: TimeRange,
  // numeric X requires a single frame where the first field is numeric
  xNumFieldIdx?: number
): DataFrame[] | null {
  if (!series?.length) {
    return null;
  }

  let useNumericX = xNumFieldIdx != null;

  // Make sure the numeric x field is first in the frame
  if (xNumFieldIdx != null && xNumFieldIdx > 0) {
    series = [
      {
        ...series[0],
        fields: [series[0].fields[xNumFieldIdx], ...series[0].fields.filter((f, i) => i !== xNumFieldIdx)],
      },
    ];
  }

  // some datasources simply tag the field as time, but don't convert to milli epochs
  // so we're stuck with doing the parsing here to avoid Moment slowness everywhere later
  // this mutates (once)
  for (let frame of series) {
    for (let field of frame.fields) {
      if (field.type === FieldType.time && typeof field.values[0] !== 'number') {
        field.values = convertFieldType(field, { destinationType: FieldType.time }).values;
      }
    }
  }

  let enumFieldsCount = 0;

  loopy: for (let frame of series) {
    for (let field of frame.fields) {
      if (field.type === FieldType.enum && ++enumFieldsCount > 1) {
        series = reEnumFields(series);
        break loopy;
      }
    }
  }

  let copy: Field;

  const frames: DataFrame[] = [];

  for (let frame of series) {
    const fields: Field[] = [];

    let hasTimeField = false;
    let hasValueField = false;

    let nulledFrame = useNumericX
      ? frame
      : applyNullInsertThreshold({
          frame,
          refFieldPseudoMin: timeRange?.from.valueOf(),
          refFieldPseudoMax: timeRange?.to.valueOf(),
        });

    const frameFields = nullToValue(nulledFrame).fields;

    for (let fieldIdx = 0; fieldIdx < frameFields?.length ?? 0; fieldIdx++) {
      const field = frameFields[fieldIdx];

      switch (field.type) {
        case FieldType.time:
          hasTimeField = true;
          fields.push(field);
          break;
        case FieldType.number:
          hasValueField = useNumericX ? fieldIdx > 0 : true;
          copy = {
            ...field,
            values: field.values.map((v) => {
              if (!(Number.isFinite(v) || v == null)) {
                return null;
              }
              return v;
            }),
          };

          fields.push(copy);
          break; // ok
        case FieldType.enum:
          hasValueField = true;
        case FieldType.string:
          copy = {
            ...field,
            values: field.values,
          };

          fields.push(copy);
          break; // ok
        case FieldType.boolean:
          hasValueField = true;
          const custom: GraphFieldConfig = field.config?.custom ?? {};
          const config = {
            ...field.config,
            max: 1,
            min: 0,
            custom,
          };

          // smooth and linear do not make sense
          if (custom.lineInterpolation !== LineInterpolation.StepBefore) {
            custom.lineInterpolation = LineInterpolation.StepAfter;
          }

          copy = {
            ...field,
            config,
            type: FieldType.number,
            values: field.values.map((v) => {
              if (v == null) {
                return v;
              }
              return Boolean(v) ? 1 : 0;
            }),
          };

          if (!isBooleanUnit(config.unit)) {
            config.unit = 'bool';
            copy.display = getDisplayProcessor({ field: copy, theme });
          }

          fields.push(copy);
          break;
      }
    }

    if ((useNumericX || hasTimeField) && hasValueField) {
      frames.push({
        ...frame,
        length: nulledFrame.length,
        fields,
      });
    }
  }

  if (frames.length) {
    setClassicPaletteIdxs(frames, theme, 0);
    matchEnumColorToSeriesColor(frames, theme);
    return frames;
  }

  return null;
}

const matchEnumColorToSeriesColor = (frames: DataFrame[], theme: GrafanaTheme2) => {
  const { palette } = theme.visualization;
  for (const frame of frames) {
    for (const field of frame.fields) {
      if (field.type === FieldType.enum) {
        const namedColor = palette[field.state?.seriesIndex! % palette.length];
        const hexColor = theme.visualization.getColorByName(namedColor);
        const enumConfig = field.config.type!.enum!;

        enumConfig.color = Array(enumConfig.text!.length).fill(hexColor);
        field.display = getDisplayProcessor({ field, theme });
      }
    }
  }
};

const setClassicPaletteIdxs = (frames: DataFrame[], theme: GrafanaTheme2, skipFieldIdx?: number) => {
  let seriesIndex = 0;
  frames.forEach((frame) => {
    frame.fields.forEach((field, fieldIdx) => {
      if (
        fieldIdx !== skipFieldIdx &&
        (field.type === FieldType.number || field.type === FieldType.boolean || field.type === FieldType.enum)
      ) {
        field.state = {
          ...field.state,
          seriesIndex: seriesIndex++, // TODO: skip this for fields with custom renderers (e.g. Candlestick)?
        };
        field.display = getDisplayProcessor({ field, theme });
      }
    });
  });
};

export function getTimezones(timezones: string[] | undefined, defaultTimezone: string): string[] {
  if (!timezones || !timezones.length) {
    return [defaultTimezone];
  }
  return timezones.map((v) => (v?.length ? v : defaultTimezone));
}

export function regenerateLinksSupplier(
  alignedDataFrame: DataFrame,
  frames: DataFrame[],
  replaceVariables: InterpolateFunction,
  timeZone: string,
  dataLinkPostProcessor?: DataLinkPostProcessor
): DataFrame {
  alignedDataFrame.fields.forEach((field) => {
    if (field.state?.origin?.frameIndex === undefined || frames[field.state?.origin?.frameIndex] === undefined) {
      return;
    }

    /* check if field has sortedVector values
      if it does, sort all string fields in the original frame by the order array already used for the field
      otherwise just attach the fields to the temporary frame used to get the links
    */
    const tempFields: Field[] = [];
    for (const frameField of frames[field.state?.origin?.frameIndex].fields) {
      if (frameField.type === FieldType.string) {
        if (field.values instanceof SortedVector) {
          const copiedField = { ...frameField };
          copiedField.values = new SortedVector(frameField.values, field.values.getOrderArray());
          tempFields.push(copiedField);
        } else {
          tempFields.push(frameField);
        }
      }
    }

    const tempFrame: DataFrame = {
      fields: [...alignedDataFrame.fields, ...tempFields],
      length: alignedDataFrame.fields.length + tempFields.length,
    };

    field.getLinks = getLinksSupplier(
      tempFrame,
      field,
      field.state!.scopedVars!,
      replaceVariables,
      timeZone,
      dataLinkPostProcessor
    );
  });

  return alignedDataFrame;
}

export const isTooltipScrollable = (tooltipOptions: VizTooltipOptions) => {
  return tooltipOptions.mode === TooltipDisplayMode.Multi && tooltipOptions.maxHeight != null;
};

/**
 * ASYSTOM STUFF
 * Custom spectrogram
 */

export const BEACON_VERSION_FOR_CUSTOM_SPECTROGRAM = 4.45;
const NUMBER_OF_BANDS = 10;

export function getOrientation(orientation: string) {
  switch (orientation) {
    case '000000':
      return 'XYZ';
    case '010000':
      return 'X';
    case '020000':
      return 'Y';
    case '040000':
      return 'Z';
    default:
      return 'N/A';
  }
}

export function asciiToUint8(str: string) {
  return parseInt(str, 16);
}

export function asciiToUint32(str: string) {
  // null, undefined and valid length checks
  if (!str.length || str.length > 8) {
    return 0;
  }

  const matchedValue = str.match(/../g);
  const decodedValue = matchedValue!.reverse().join('');
  return parseInt('0x' + decodedValue, 16);
}

export const convertSettingsValue = (settingsValue: string, spectrumSettings: SpectrogramSettings, dico: DicoProps) => {
  const sensorType = asciiToUint8(settingsValue.substring(0, 2));
  const orientation = settingsValue.substring(2, 8);
  const spectrumType = asciiToUint32(settingsValue.substring(40, 44));
  const cutoffValue = asciiToUint32(settingsValue.substring(44, 48));

  spectrumSettings.spectrumType = getSpectrumType(spectrumType, dico.spectrumType);
  spectrumSettings.cutoffValue = cutoffValue.toString(10);
  spectrumSettings.orientation = getOrientation(orientation);
  spectrumSettings.sensorType = getSensorType(sensorType, dico.sensorType);

  if (sensorType === 12) {
    spectrumSettings.maxFreq = asciiToUint32(settingsValue.substring(8, 12));
    spectrumSettings.minFreq = asciiToUint32(settingsValue.substring(12, 16));
    spectrumSettings.maxFreq *= 10;
    spectrumSettings.minFreq *= 10;
    // panel.yaxes[0].format = 'dB';
  }

  if (sensorType === 3) {
    spectrumSettings.maxFreq = asciiToUint32(settingsValue.substring(16, 20));
    spectrumSettings.minFreq = asciiToUint32(settingsValue.substring(20, 24));
    //  panel.yaxes[0].format = 'g';
    //   if (spectrumType === 5 || spectrumType === 6) {
    //     panel.yaxes[0].format = this.lengthUnitTemplate === 'meter' ? 'mm/s' : 'in/s';
    //   }
  }

  return spectrumSettings;
};

export function getSensorType(sensor: number, sensorTypes: { microphone: string; accelerometer: string }) {
  switch (sensor) {
    case 12:
      return sensorTypes.microphone;
    case 3:
      return sensorTypes.accelerometer;
    default:
      return `Unknown sensor ${sensor}`;
  }
}

export const getDecimals = (yaxesFormat: string) => {
  let decimals = 0;
  switch (yaxesFormat) {
    case 'g':
      decimals = 5;
      break;
    case 'mm/s':
      decimals = 2;
      break;
    case 'in/s':
      decimals = 4;
      break;
    default:
      decimals = 1;
      break;
  }
  return decimals;
};

export function getSpectrumType(spectrumType: number, spectrumTypes: { [key: string]: string }) {
  switch (spectrumType) {
    case 1:
      return spectrumTypes.rms;
    case 2:
      return spectrumTypes.peak;
    case 3:
      return spectrumTypes.velRms;
    case 4:
      return spectrumTypes.velPeak;
    case 5:
      return spectrumTypes.envRms;
    case 6:
      return spectrumTypes.envPeak;
    default:
      return 'N/A';
  }
}

export function getSpectrumUnit(spectrumSettings: SpectrogramSettings, dico: DicoProps, lengthUnit: string) {
  const { spectrumType, sensorType } = dico;

  switch (spectrumSettings.spectrumType) {
    case spectrumType.rms:
      return spectrumSettings.sensorType === sensorType.microphone ? 'dB' : 'g';
    case spectrumType.peak:
      return spectrumSettings.sensorType === sensorType.microphone ? 'dB' : 'g';
    case spectrumType.velRms:
      return lengthUnit === 'meter' ? 'mm/s' : 'in/s';
    case spectrumType.velPeak:
      return lengthUnit === 'meter' ? 'mm/s' : 'in/s';
    case spectrumType.envRms:
      return 'g';
    case spectrumType.envPeak:
      return 'g';
    default:
      return 'N/A';
  }
}

export function getCustomSpectrogramQuerySelect(
  field: string,
  spectrumSettings: SpectrogramSettings,
  spectrumTypes: { [key: string]: string },
  sensorTypes: { [key: string]: string },
  lengthUnit: string
) {
  const { spectrumType, sensorType } = spectrumSettings;
  // RMS / Peak
  if (spectrumType === spectrumTypes.rms || spectrumType === spectrumTypes.peak) {
    if (sensorType === sensorTypes.accelerometer) {
      return `16*POW(10, max(\"${field}\")/20)`;
    }
    return `max(\"${field}\")`;
  }
  // Velocity RMS || Peak
  if (spectrumType === spectrumTypes.velRms || spectrumType === spectrumTypes.velPeak) {
    return lengthUnit === 'meter' ? `100*POW(10, max(\"${field}\")/20)` : `(100*POW(10, max(\"${field}\")/20)) / 25.4`; // in/s support
  }
  // Envelope RMS || peak
  if (spectrumType === spectrumTypes.envRms || spectrumType === spectrumTypes.envPeak) {
    return `16*POW(10, max(\"${field}\")/20)`;
  }
  // default
  return `max(\"${field}\")`;
}

export function getCustomSpectrumFreqRanges(maxFreq: number, minFreq: number, seriesSpectrum: SerieSpectrumProps[]) {
  let freqRange = 0;
  let currentFreq = 0;
  freqRange = (maxFreq - minFreq) / NUMBER_OF_BANDS;
  currentFreq = minFreq;
  for (const queryData of seriesSpectrum) {
    const isThousandSuperior = currentFreq + freqRange >= 1000;
    queryData.freqInterval = `${isThousandSuperior ? currentFreq / 1000 : currentFreq}-${
      isThousandSuperior ? (currentFreq + freqRange) / 1000 : currentFreq + freqRange
    } ${isThousandSuperior ? 'kHz' : 'Hz'}`;
    currentFreq = currentFreq + freqRange;
  }
  return seriesSpectrum;
}

export const seriesSpectrum: SerieSpectrumProps[] = [
  { field: 's_10', refId: 'A', freqInterval: '0-8 kHz' },
  { field: 's_11', refId: 'B', freqInterval: '8-16 kHz' },
  { field: 's_12', refId: 'C', freqInterval: '16-24 kHz' },
  { field: 's_13', refId: 'D', freqInterval: '24-32 kHz' },
  { field: 's_14', refId: 'E', freqInterval: '32-40 kHz' },
  { field: 's_15', refId: 'F', freqInterval: '40-48 kHz' },
  { field: 's_16', refId: 'G', freqInterval: '48-56 kHz' },
  { field: 's_17', refId: 'H', freqInterval: '56-64 kHz' },
  { field: 's_18', refId: 'I', freqInterval: '64-72 kHz' },
  { field: 's_19', refId: 'J', freqInterval: '72-80 kHz' },
];

interface TargetProps {
  alias: string;
  dsType: string;
  groupBy: string[];
  measurement: string;
  orderByTime: string;
  policy: string;
  query: string;
  rawQuery: boolean;
  refId: string;
  resultFormat: string;
  select: string[];
  tags: string[];
}

export const getNewTargets = (
  settings: SpectrogramSettings,
  macAddress: string,
  timeCondition: string,
  spectrumTypes: { [key: string]: string },
  sensorTypes: { [key: string]: string },
  lengthUnit: string
) => {
  // Modify queries
  const newTargets = [] as TargetProps[];

  const newSeriesSpectrum = getCustomSpectrumFreqRanges(settings.maxFreq, settings.minFreq, seriesSpectrum);

  for (const dataQuery of newSeriesSpectrum) {
    const querySelect = getCustomSpectrogramQuerySelect(
      dataQuery.field,
      settings,
      spectrumTypes,
      sensorTypes,
      lengthUnit
    );

    newTargets.push({
      alias: dataQuery.freqInterval,
      dsType: 'influxdb',
      groupBy: [],
      measurement: 'Signature',
      orderByTime: 'ASC',
      policy: 'default',
      query: `SELECT ${querySelect} FROM \"Signature\" WHERE \"device\"='${macAddress}' AND ${timeCondition} GROUP BY time($__interval) fill(none)`,
      rawQuery: true,
      refId: `${dataQuery.refId}`,
      resultFormat: 'time_series',
      select: [],
      tags: [],
    });
  }
  return newTargets;
};

// QUERIES
/** SELECT version, last(value) FROM SettingsPrivateBackup WHERE device = {macAddress} AND version = '' AND time <= {timeTo}ms ORDER BY time DESC LIMIT 1 */
export const getUserSettingsQuery = (macAddress: string, timeTo: number) =>
  `SELECT version, last(value) FROM SettingsPrivateBackup WHERE device = '${macAddress}' AND version = '' AND time <= ${timeTo}ms ORDER BY time DESC LIMIT 1`;

/** SELECT version, first(value) FROM SettingsPrivateBackup WHERE device = {macAddress} AND version != '' AND value = {settings} AND time > {userSettingsTimestamp}ms ORDER BY time DESC LIMIT 1 */
export const getFirstSettingsReceivedQuery = (macAddress: string, settings: string, timestamp: number) =>
  `SELECT version, first(value) FROM SettingsPrivateBackup WHERE device = '${macAddress}' AND version != '' AND value = '${settings}' AND time > ${timestamp}ms ORDER BY time DESC LIMIT 1`;

/** `SELECT value FROM SettingsPrivateBackup WHERE device = '${macAddress}' AND value != '${settings}' AND time <= ${timeTo}ms AND time >= ${receptionDate}ms AND time >= ${timeFrom}ms` */
export const getNoOtherSettingsQuery = (
  macAddress: string,
  settings: string,
  timePanel: {
    from: number;
    to: number;
  },
  receptionDate: number
) =>
  `SELECT value FROM SettingsPrivateBackup WHERE device = '${macAddress}' AND value != '${settings}' AND time <= ${timePanel.to}ms AND time >= ${receptionDate}ms AND time >= ${timePanel.from}ms`;

/** @return queryResult.results[0].series */
export const influxRequest = async (datasource: DataSourceApi, query: string) => {
  const urlQuery = `api/datasources/proxy/uid/${datasource?.uid}/query?db=${datasource.name}&q=${query}&epoch=ms`;
  const queryResult = await getBackendSrv()
    .request({
      url: urlQuery,
      method: 'get',
    })
    .catch((err) => {
      console.error(err);
    });

  if (!queryResult.results[0].series) {
    return [];
  }

  return queryResult.results[0].series;
};

export const getTemplates = () => {
  const macAddress = getTemplateSrv().replace('$beacon_selection');
  const lengthUnit = getTemplateSrv().replace('$length_unit');

  if (!macAddress) {
    return;
  }
  return { macAddress: macAddress, lengthUnit: lengthUnit };
};
